.login {

    padding-top: 210px;
    background:#F7F7F7 url(./images/login_bg.png) top center no-repeat;
    .mt20 {
      margin-top: 30px;
    }
    .w100 {
      width: 100%;
    }
  
    .main {
      box-sizing: content-box;
      width: 400px;
      padding-left: 500px;
      background:#fff url('./images/login_content_bg.jpg') 60px 60px no-repeat;
      margin: 0 auto;
      text-align: center;
  
      border-radius: 4px;
      border: 1px solid #d8d8d8;
      .accountWrap {
        width: 300px;
        min-height: 358px;
        margin: 0 auto;
        padding: 0 50px;
        background: url('./images/login_form_leftline.jpg') left center no-repeat;
        h2 {
          height: 65px;
          line-height: 65px;
        }
        .mainForm {
          padding-top: 20px;
        }
      }
    }
  }

  .login-form .login-form-button {
    width: 100%;
  }
  .login-form .ant-form-item-explain {
    text-align: left;
  }
@primary-color: #1DA57A;